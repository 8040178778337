<template>
  <div class="page">
    <h2>搜索商品@TODO</h2>
    <chooseGoods @handleChange="handleChange"/>
    <h2>批量操作</h2>
    <el-form label-width="120px" label-position="left">
      <el-form-item label="">
        <el-button v-if="!picUrl" icon="el-icon-plus" plain style="width: 100px; height: 100px;border-radius: 10px" @click="showFileSelect = true"/>
        <el-image v-else :src="picUrl" :key="picUrl" style="width: 100px; height: 100px;border-radius: 10px" :preview-src-list="[picUrl]"/>
      </el-form-item>
    </el-form>

    <div class="fucView">
      <el-button type="primary" :disabled="choosedGoodsList.length == 0" @click="save">保存</el-button>
    </div>
    <fileSelect :visible.sync="showFileSelect" title="选择商品图片" state="image" @confirm="showFileSubmit"/>
  </div>
</template>

<script>
import chooseGoods  from "@/views/tools/components/chooseGoods";
import fileSelect   from "@/views/components/fileSelect";
export default {
  name: "setGoodsPic",
  components: {
    chooseGoods,
    fileSelect
  },
  data() {
    return {
      picUrl: "",
      choosedGoodsList: [],
      showFileSelect: false,
    };
  },
  methods: {
    handleChange(value) {
      this.choosedGoodsList = value;
    },
    showFileSubmit(value) {
      this.picUrl = value;
    },
    save(){}
  },
}
</script>

<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
  /*margin: 20px;*/
}
.el-form-item {
  margin-bottom: 10px;
}
.fucView {
  display: flex;
  align-items: center;
  margin-left: 120px;
}
</style>